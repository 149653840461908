import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { ConvenienceInterface, MarkupState } from '../types';

export const initialState: MarkupState = {
  loading: false,
  ConvenienceMarkupFormData: {
    _id: '',
    DomesticDiscountValue: '0',
    InternationalDiscountValue: '0',
    cancellationCharge: 0,
    defaultMarkup: 0
  }
};

export const useClinicSlice = createSlice({
  name: 'MarkupState',
  initialState,
  reducers: {
    updateNewMarkupFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      //@ts-ignore
      state.ConvenienceMarkupFormData[action.payload.key] = action.payload.value;
    },
    doGetMarkupList: (state) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearMarkupFrom: (state) => {
      state.ConvenienceMarkupFormData._id = '';
      state.ConvenienceMarkupFormData.DomesticDiscountValue = '';
      state.ConvenienceMarkupFormData.InternationalDiscountValue = '';
      state.ConvenienceMarkupFormData.cancellationCharge = 0;
      state.ConvenienceMarkupFormData.defaultMarkup = 0;
    },

    setDataForEdit: (state, action: PayloadAction<ConvenienceInterface>) => {
      state.ConvenienceMarkupFormData = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }

    // International  Markup API
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
