'use client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { set } from 'lodash';
import { BlogCategoryEnum, DataInterface, ImageInterface } from './types';

export const useClinicSlice = createSlice({
  name: 'blogState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setTotalList: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearFrom: (state) => {
      state.form._id = "";
      state.form.category = BlogCategoryEnum.TRENDING;
      state.form.description = "";
      state.form.title = "";
      state.form.title = "";
    },
    doAdd: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => { },
    doGetDataById: (
      state,
      action: PayloadAction<{
        id: string,
        callback: () => void;
      }>
    ) => { },
    setDataInForm: (state, action: PayloadAction<DataInterface>) => {
      state.form = action.payload;
    },
    doGetList: (state) => { },
    doUpdate: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => { },
    doDelete: (
      state,
      action: PayloadAction<{
        id: string,
        callback: () => void;
      }>
    ) => { },
    setList: (state, action: PayloadAction<Array<DataInterface>>) => {
      state.list = action.payload;

    },
    // file upload
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadeImage: (state, action: PayloadAction<{ data: any; signedUrl: string; result: any; callback: any }>) => {},
    setImageList: (state, action: PayloadAction<ImageInterface>) => {
      state.form.imageStatus.push(action.payload);
    },
    setImageStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.form.imageStatus[i].isDelete = true;
      }
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
