import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../NewTypes/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state) {
    return state.OfferState;
  } else {
    return initialState;
  }
};

export const selectOfferForm = createSelector(
  [selectDomain],
  (state) => state.OfferFormData
);
export const selectOfferListData = createSelector(
  [selectDomain],
  (state) => state.OfferList
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const SelectDeleteOffer = createSelector([selectDomain], (state) => state.deleteOffer);
export const SelectDeleteLoading = createSelector([selectDomain], (state) => state.deleteLoading);