import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { AnnouncementInterface, AnnouncementState } from '../types';
import { set } from 'lodash';
import moment from 'moment';
export const initialState: AnnouncementState = {
  AnnouncementList: [],
  form: {
    message: '',
    start: '',
    end: '',
    _id: '',
    mandatory: true
  },
  editIndex: 0,
  loading: false,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  dissableCheck: false,
  sortColumn: '',
  sortDir: '',
  delete: false,
  deleteLoading: false,
};

export const useClinicSlice = createSlice({
  name: 'AnnouncementState',
  initialState,
  reducers: {
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    NewAnnouncementAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    doGetAnnouncementList: (state) => {},
    clearNewAnnouncementFrom: (state) => {
      state.form._id = '';
      state.form.message = '';
      state.form.start = '';
      state.form.end = '';
      state.form.mandatory = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.deleteLoading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    toggleDelete: (state, action: PayloadAction<boolean>) => {
      state.delete = action.payload;
    },
    setDayList: (state, action: PayloadAction<Array<AnnouncementInterface>>) => {
      state.AnnouncementList = action.payload;
    },
    AnnouncementDelete: (state, action: PayloadAction<{id: string, callback: any}>) => {},
    AnnouncementEdit: (state, action: PayloadAction<string>) => {},
    setAnnouncementForEdit: (state, action: PayloadAction<AnnouncementInterface>) => {
      state.form._id = action.payload._id;
      state.form.message = action.payload.message;
      state.form.mandatory = action.payload.mandatory;
      state.form.start = moment(action.payload.start).format('YYYY-MM-DD').toString();
      state.form.end = moment(action.payload.end).format('YYYY-MM-DD').toString();
    },
    updateDataAnnouncement: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
