import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir, selectStatus } from './selector';
import { GetUserlistRequest, getUserProfileDetailsRequest, getUserBookingDetailsRequest } from 'utils copy/request';
import CatchBlockFunction from 'components/CatchBlockFunction';

export function* getUserListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetUserlistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setUserList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetUserDetailsRequest(action: { payload: string }) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getUserProfileDetailsRequest, action.payload);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    yield put(actions.setUserProfileDetails(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetUserBookingDetailsRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const status: string = yield select(selectStatus);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getUserBookingDetailsRequest,
      action.payload.id,
      `?pageNo=${pageNo}&pageSize=${pageSize}&statusFilter=${status}
      `
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setUserBookingDetails(response.data.data.results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* authRepoSagaUser() {
  yield takeLatest(actions.doGetUserList, getUserListRequest);
  yield takeLatest(actions.setSearch, getUserListRequest);
  yield takeLatest(actions.doGetUserProfileDetails, doGetUserDetailsRequest);
  yield takeLatest(actions.doGetUserBookingDetails, doGetUserBookingDetailsRequest);
}
