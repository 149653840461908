import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { uploadHomepageImage, updateHomepageBannerImage, getHomepageBanners } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'components/CatchBlockFunction';
import { HomepageBannerInterface } from '../types';
import { selectForm } from './selector';

export function* doGetListRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(getHomepageBanners);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      });
      return;
    }

    yield put(actions.setData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUploadImageRequest(action: { payload: { file: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(uploadHomepageImage, action.payload.file);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield put(
      actions.updateImageValue({
        value: response.data.data
      })
    );
    yield call(action?.payload?.callback(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: HomepageBannerInterface = yield select(selectForm);

  if (form?.images?.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please upload at least 1 image',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(updateHomepageBannerImage, form);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* homepageBannerRepoSaga() {
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doAddImage, doUploadImageRequest);
}

// questionAddRequestMain
