
import {
  AirlineState,
  AirlineStatusEnum,
} from ".";


export const initialState: AirlineState = {
  loading: false,
  search: "",
  form: {
    _id: "",
    name: "",
    code: "",
    image: "image",
    imageStatus: [],
    isActive: AirlineStatusEnum.ACTIVE
  },
  list: [],
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  sortColumn: "",
  sortDir: "",
};
