import { CancelBookingQueryState, QueryStatusEnum } from '.';

export const initialState: CancelBookingQueryState = {
  loading: false,
  search: '',

  form: { _id: '', name: '', email: '', phoneNo: '', description: '', queryStatus: QueryStatusEnum.ALL },
  list: [],
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  sortColumn: '',
  sortDir: ''
};
