import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { IRefundData, RefundState } from '../types';

export const initialState: RefundState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  RefundFormData: {
    refundAmount: '',
    message: '',
    paymentStatus: 'Refunded',
    refundDate: '',
    paymentId: ''
  },
  RefundList: [],
  recentRefundList: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  generateRefund: false,
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'RefundState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setGenerateRefund: (state, action: PayloadAction<boolean>) => {
      state.generateRefund = action.payload;
    },

    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    doGetRefundList: (state) => {},
    setRefundList: (state, action: PayloadAction<Array<IRefundData>>) => {
      state.RefundList = action.payload;
    },
    doGetRecentRefundList: (state) => {},

    setRecentRefundList: (state, action: PayloadAction<Array<IRefundData>>) => {
      state.recentRefundList = action.payload;
    },
    payment: (state, action: PayloadAction<{ callback: () => void }>) => {},
    updateRefundFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      //@ts-ignore
      state.RefundFormData[action.payload.key] = action.payload.value;
    },
    clearRefundFrom: (state) => {
      state.RefundFormData.message = '';
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    doGenerateRefund: (state, action: PayloadAction<{ id: string; callback: () => void }>) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
