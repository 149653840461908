import axios from 'axios';
import Api from './Api';

//new request code by richa
const LoginRequest = async (data: any) => {
  return Api.post(`adminlogin`, data);
};
const MeProfileRequest = async () => {
  return Api.get(`user/me`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const SaveFaqRequest = async (data: any) => {
  return Api.post(`/faq/createfaq`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetFaqlistRequest = async (filter: string) => {
  return Api.get(`/faq/allfaq${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const DeleteFaqRequest = async (id: string) => {
  return Api.delete(`/faq/deletefaq/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GeFaqEditRequest = async (id: string) => {
  return Api.put(`/faq/updatefaq/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GeFaqEdit = async (id: string) => {
  return Api.get(`/faq/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const UpdateHelpRequest = async (data: any) => {
  return Api.put(`/faq/updatefaq/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
//Announcement API
const SaveAnnouncementRequest = async (data: any) => {
  return Api.post(`/announcement/createannouncement`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetAnnouncementlistRequest = async (filter: string) => {
  return Api.get(`/announcement/allannouncement${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const getAnnouncementEditRequest = async (id: string) => {
  return Api.get(`/announcement/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const DeleteAnnouncementRequest = async (id: string) => {
  return Api.delete(`/announcement/deleteannouncement/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const UpdateAnnouncementRequest = async (data: any) => {
  return Api.put(`/announcement/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

//Role User
const SaveRoleUserRequest = async (data: any) => {
  return Api.post(`/user/admin/register`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetRoleUserlistRequest = async (filter: string) => {
  return Api.get(`/user/admin/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
// const GetRoleUserEditRequest = async (id: string) => {
//   return Api.put(`/user/admin/update/user/${id}`, {
//     headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
//   });
// };
const GetRoleUserEditRequest = async (data: any) => {
  console.log(data, 'GetRoleUserEditRequest');
  return Api.put(`/user/admin/update/user`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

//markup

const SaveMarkupRequest = async (data: any) => {
  return Api.post(`/commision/createCommision`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetComisionAndAllComisionRequest = async () => {
  return Api.get(`/commision/allCommision`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const UpdateMarkupRequest = async (data: any) => {
  return Api.put(`/commision/updatecommision/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const DeleteMarkupRequest = async (CommisionId: string) => {
  return Api.delete(`/commision/deleteCommision/${CommisionId}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

//new markup

const SaveNewMarkupRequest = async (data: any) => {
  return Api.post(`/markup/createmarkup`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetNewMarkuplistRequest = async (filter: string) => {
  return Api.get(`/markup/allmarkup${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetAirlilneslistRequest = async () => {
  return Api.get(`/markup/allairline`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetEditMarkupRequest = async (id: string) => {
  return Api.get(`/markup/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const DeleteNewMarkupRequest = async (markupId: string) => {
  return Api.delete(`/markup/deletemarkup/${markupId}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const UpdateNewMarkupRequest = async (data: any) => {
  return Api.put(`/markup/updatemarkup/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
//Refund
const getRefundlistRequest = async (filter: string) => {
  return Api.get(`/airports/admin/get-refund-flight-booking-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const getRecentRefundlistRequest = async (filter: string) => {
  return Api.get(`/airports/admin/recent-refund-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetBookingslistRequest = async (filter: string) => {
  return Api.get(`/airports/getallflightbookingAdmin${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const getBookingDetailsRequest = async (id: string) => {
  return Api.get(`/airports/booking-detail/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const UpdatePassword = async (data: any) => {
  return Api.patch(`/change-password`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

const GetUserlistRequest = async (filter: string) => {
  return Api.get(`/user/list/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const getUserProfileDetailsRequest = async (id: string) => {
  return Api.get(`/user/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const getUserBookingDetailsRequest = async (id: string, filter: string) => {
  return Api.get(`/airports/get-user/all-flight-booking/${id}${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

const CancleRefundRequest = async (data: any) => {
  return Api.post(`/payments/updatePayments`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GenerateRefund = async (id: string) => {
  return Api.get(`/payments/refund/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

//dashboard

const DashboardListRequest = async (filter: string) => {
  return Api.get(`/dashboard/all/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

const TransactionListRequest = async (filter: string) => {
  return Api.get(`/payments/transaction-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

const WalletDataRequest = async () => {
  return Api.get(`/airports/getApiBalance`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetDashboardGraphDataRequest = async (data: any) => {
  return Api.get(`/dashboard/graph/${data}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetDashboardSearchRequest = async () => {
  return Api.get(`/dashboard/search/month`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetTicketStatusRequest = async (referenceNo: any) => {
  return Api.get(`/airports/flightticketstatus/${referenceNo}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

const SaveOfferRequest = async (data: any) => {
  return Api.post(`/offer/createoffer`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetOfferlistRequest = async (filter: string) => {
  return Api.get(`/offer/admin/all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const getOfferEditRequest = async (id: string) => {
  return Api.get(`/offer/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

const uploadOfferImage = async (data: any) => {
  return Api.put(`/offer/upload`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GeUploadeRequest = async (data: any) => {
  return Api.put(`/offer/upload`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};
const DeleteOfferRequest = async (offerId: string) => {
  return Api.delete(`/offer/deleteoffer/${offerId}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const updateOfferRequest = async (data: any) => {
  return Api.put(`offer/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

// coupon section //
const SaveCouponRequest = async (data: any) => {
  return Api.post(`/coupon/createCoupon`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetCouponlistRequest = async (filter: string) => {
  return Api.get(`/coupon/allCoupon${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetCouponEditRequest = async (id: string) => {
  return Api.get(`/coupon/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

const GeUploadeCouponRequest = async (data: any) => {
  return Api.put(`/coupon/upload/`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('auth_token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};
const DeleteCouponRequest = async (CouponId: any) => {
  return Api.delete(`/coupon/deleteCoupon/${CouponId}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const UpdateCouponRequest = async (data: any) => {
  return Api.put(`coupon/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
// coupon section //
const UpdatePolicy = async (data: any) => {
  return Api.put(`/policy/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetPolicy = async () => {
  return Api.get(`/policy/get`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetUserContactList = async (filter: any) => {
  return Api.get(`/contact-us/list/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const UpdateUserContactStatus = async (id: string, data: any) => {
  return Api.put(`/contact-us/update/${id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
//Blog
const saveBlog = async (data: any) => {
  return Api.post(`/blog/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetBlogList = async (filter: any) => {
  return Api.get(`/blog/list/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetBlogById = async (id: string) => {
  return Api.get(`/blog/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const UpdateBlog = async (data: any) => {
  return Api.put(`/blog/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const deleteBlog = async (id: string) => {
  return Api.delete(`/blog/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
//
const uploadAirlineImage = async (data: any) => {
  return Api.put(`/airline/upload`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const flagSingedUrl = async (data: any) => {
  return Api.post(
    `/airline/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const uplodeFlagImage = async (signedUrl: any, data: any, contentType: any) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      'Content-Type': contentType
    }
  });
};
const saveAirline = async (data: any) => {
  return Api.post(`/airline/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetAirlineList = async (filter: any) => {
  return Api.get(`/airline/list/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const GetAirlineById = async (id: string) => {
  return Api.get(`/airline/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const UpdateAirline = async (data: any) => {
  return Api.put(`/airline/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const deleteAirline = async (id: string) => {
  return Api.delete(`/airline/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

const GetCancelBookingQueryList = async (filter: any) => {
  return Api.get(`/contact-us/list/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const UpdateCancelBookingQueryStatus = async (id: string, data: any) => {
  return Api.put(`/contact-us/update/${id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const uploadHomepageImage = async (data: any) => {
  return Api.put(`homepage/upload`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const updateHomepageBannerImage = async (data: any) => {
  return Api.put(`/homepage/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};
const getHomepageBanners = async () => {
  return Api.get(`/homepage/banner/list`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('auth_token') }
  });
};

export {
  LoginRequest,
  MeProfileRequest,
  SaveFaqRequest,
  GetFaqlistRequest,
  DeleteFaqRequest,
  GeFaqEditRequest,
  GeFaqEdit,
  UpdateHelpRequest,
  UpdateCouponRequest,
  SaveAnnouncementRequest,
  GetRoleUserlistRequest,
  GetAnnouncementlistRequest,
  getAnnouncementEditRequest,
  UpdateAnnouncementRequest,
  SaveRoleUserRequest,
  DeleteAnnouncementRequest,
  DeleteNewMarkupRequest,
  SaveMarkupRequest,
  SaveNewMarkupRequest,
  GetNewMarkuplistRequest,
  GetEditMarkupRequest,
  GetComisionAndAllComisionRequest,
  UpdateMarkupRequest,
  DeleteMarkupRequest,
  GetUserlistRequest,
  getUserProfileDetailsRequest,
  getUserBookingDetailsRequest,
  getRefundlistRequest,
  getRecentRefundlistRequest,
  GetBookingslistRequest,
  getBookingDetailsRequest,
  GetAirlilneslistRequest,
  CancleRefundRequest,
  GetRoleUserEditRequest,
  DashboardListRequest,
  GetDashboardGraphDataRequest,
  WalletDataRequest,
  GetTicketStatusRequest,
  GetDashboardSearchRequest,
  SaveOfferRequest,
  GetOfferlistRequest,
  getOfferEditRequest,
  updateOfferRequest,
  uploadOfferImage,
  GeUploadeRequest,
  DeleteOfferRequest,
  SaveCouponRequest,
  GetCouponlistRequest,
  GetCouponEditRequest,
  GeUploadeCouponRequest,
  DeleteCouponRequest,
  UpdateNewMarkupRequest,
  UpdatePolicy,
  GetPolicy,
  GetUserContactList,
  UpdateUserContactStatus,
  saveBlog,
  GetBlogList,
  GetBlogById,
  UpdateBlog,
  deleteBlog,
  uploadAirlineImage,
  flagSingedUrl,
  uplodeFlagImage,
  saveAirline,
  GetAirlineList,
  GetAirlineById,
  UpdateAirline,
  deleteAirline,
  UpdateCancelBookingQueryStatus,
  GetCancelBookingQueryList,
  UpdatePassword,
  TransactionListRequest,
  uploadHomepageImage,
  updateHomepageBannerImage,
  getHomepageBanners,
  GenerateRefund
};
