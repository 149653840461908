import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { BookingDetailsInterface, BookingsState, DataInterface, FlightBookingStatusEnum, StatusFilterEnum } from '../types';

export const initialState: BookingsState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  list: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  sortColumn: '',
  sortDir: '',
  status: StatusFilterEnum.Upcoming,
  bookingDetails: {
    _id: '',
    trivzyBookingId: '',
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    isReturn: false,
    status: FlightBookingStatusEnum.Booked,
    convenienceFeeAmount: 0,
    markupTotal: 0,
    tripDate: '',
    PNR: '',
    BookingId: '',
    netTotal: 0,
    bookingDetailsRequest: {
      AirlineCode: '',
      AirlineName: '',
      FlightNumber: '',
      FareClass: '',
      OriginAirportName: '',
      OriginCityName: '',
      DepTime: '',
      DestinationAirportName: '',
      DestinationCityName: '',
      ArrTime: ''
    },
    passengers: []
  }
};

export const useClinicSlice = createSlice({
  name: 'BookingsState',
  initialState,
  reducers: {
    doGetBookingList: (state) => {},
    setList: (state, action: PayloadAction<Array<DataInterface>>) => {
      state.list = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
     
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
    
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
     
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      
      state.pageSize = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setStatus: (state, action: PayloadAction<StatusFilterEnum>) => {
      state.status = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    doGetBookingDetails: (state, action: PayloadAction<string>) => {},
    setBookingDetails: (state, action: PayloadAction<BookingDetailsInterface>) => {
      state.bookingDetails = action.payload;
    },
    clearBookingDetails: (state) => {
      state.bookingDetails._id = '';
      state.bookingDetails.trivzyBookingId = '';
      state.bookingDetails.firstName = '';
      state.bookingDetails.lastName = '';
      state.bookingDetails.email = '';
      state.bookingDetails.mobileNumber = '';
      state.bookingDetails.BookingId = '';
      state.bookingDetails.PNR = '';
      state.bookingDetails.tripDate = '';
      state.bookingDetails.passengers = [];
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
