import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { CouponDiscountTypeEnum, CouponState, ICoupon, StatusEnum } from '../types';
import { set } from 'lodash';

export const initialState: CouponState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  CouponList: [],
  CouponFormData: {
    description: '',
    _id: '',
    couponDiscount: '',
    couponDiscountPercentage: '',
    couponDiscountType: CouponDiscountTypeEnum.VALUE,
    status: StatusEnum.ACTIVE,
    title: '',
    createdAt: '',
    updatedAt: ''
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  sortColumn: '',
  sortDir: '',
  deleteCoupon: false,
  deleteLoading: false
};

export const useClinicSliceTodayActivity = createSlice({
  name: 'CouponState',
  initialState,
  reducers: {
    updateNewCouponFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `CouponFormData.${action.payload.key}`, action.payload.value);
    },
    NewCouponAdded: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearNewCouponFrom: (state) => {
      state.CouponFormData._id = '';
      state.CouponFormData.description = '';
      state.CouponFormData.title = '';
      state.CouponFormData.couponDiscount = "";
      state.CouponFormData.couponDiscountPercentage = "";
      state.CouponFormData.couponDiscountType = CouponDiscountTypeEnum.VALUE;
      state.CouponFormData.status = StatusEnum.ACTIVE;
    },
    CouponDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    CouponEdit: (state, action: PayloadAction<any>) => {},
    doGetCouponList: (state) => {},
    UploadeCoupon: (state, action: PayloadAction<any>) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setDayList: (state, action: PayloadAction<Array<ICoupon>>) => {
      state.CouponList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    toggleDeleteCoupon: (state, action: PayloadAction<boolean>) => {
      state.deleteCoupon = action.payload;
    },
    setDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.deleteLoading = action.payload;
    },
    setCouponForEdit: (state, action: any) => {
      state.CouponFormData = action.payload;
    },
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doAddImages: (state, action: PayloadAction<{ data: any; callback: any }>) => {},
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSliceTodayActivity;
