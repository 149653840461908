'use client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { DataInterface, QueryStatusEnum } from './types';

export const useClinicSlice = createSlice({
  name: 'cancelBookingQueryState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setTotalList: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    doGetList: (state) => {},
    doUpdateStatusData: (
      state,
      action: PayloadAction<{
        id: string;
        status: number;
        callback: () => void;
      }>
    ) => {},
    setList: (state, action: PayloadAction<Array<DataInterface>>) => {
      state.list = action.payload;
    },
    setFilter: (state, action: PayloadAction<QueryStatusEnum>) => {
      state.form.queryStatus = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
