import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';


// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  // const theme = useTheme();
  // const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // const menu = useSelector((state: RootStateProps) => {
  //   console.log("state.menu", state)
  //   if (state) {
  //     return state.menu
  //   } else {
  //     return initialState
  //   }


  // });
  // const { drawerOpen } = menu;

  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Navigation />
      {/* {drawerOpen && !matchDownMD && <NavCard />} */}
    </SimpleBar>
  );
};

export default DrawerContent;
