import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { Booking, DashboardInterface, DashboardState } from '../types';

export const initialState: DashboardState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  DashboardWallet: 0,
  DashboardFormData: {
    profit: '',
    queue: '',
    refund: '',
    revenue: '',
    allSuccessPayment: [],
    allRefundPaymnet: []
  },
  DashboardSearchList: {},
  DashboardGraphDataList: [],
  DashboardList: {
    profit: '',
    queue: '',
    refund: '',
    revenue: '',
    allSuccessPayment: [],
    allRefundPaymnet: []
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  sortColumn: '',
  sortDir: '',
  TransactionList: []
};

export const useClinicSlice = createSlice({
  name: 'DashboardState',
  initialState,
  reducers: {
    doGetDashboardList: (state) => {},
    doGetTransactionList: (state) => {},
    doGetAgencyWallet: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetSearchData: (state) => {},
    doGetDashboardGraphData: (state, action: PayloadAction<any>) => {},
    setDashboardsList: (state, action: PayloadAction<DashboardInterface>) => {
      state.DashboardList = action.payload;
    },
    setTransactionListt: (state, action: PayloadAction<Array<Booking>>) => {
      state.TransactionList = action.payload;
    },
    setGraphData: (state, action: PayloadAction<DashboardInterface>) => {
      state.DashboardGraphDataList = action.payload;
    },
    setWalletData: (state, action: PayloadAction<DashboardInterface>) => {
      state.DashboardWallet = action.payload;
    },
    setSearchData: (state, action: PayloadAction<DashboardInterface>) => {
      state.DashboardSearchList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
