// material-ui
import { Box, InputAdornment } from '@mui/material';
import { ReloadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { SelectDashboardWalletData } from 'pages/dashboard/redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'pages/dashboard/redux/slice';

function Search() {
  const WalletData = useSelector(SelectDashboardWalletData);
  const dispatch = useDispatch();
  const [spin, setSpin] = useState(false);
  const handleChange = () => {
    setSpin(true);
  };
  useEffect(() => {
    setTimeout(() => {
      myFunction();
    }, 100);

    return () => {};
  }, [spin]);

  const myFunction = () => {
    dispatch(
      actions.doGetAgencyWallet({
        callback: () => {
          setSpin(false);
        }
      })
    );
  };
  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Box sx={{ border: '2px solid #D3D3D3 ', px: 2, py: 1, display: 'flex', alignItems: 'center' }}>
          <b>Agency Balance</b>
          <Box>
            <InputAdornment position="start" sx={{ p: 1 }}>
              <b>
                <ReloadOutlined spin={spin} onClick={handleChange} twoToneColor="#eb2f96" />
              </b>
            </InputAdornment>
          </Box>
          <Box>
            <b>{WalletData?.toFixed(0)}</b>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Search;
