
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";


import CatchBlockFunction from "components/CatchBlockFunction";
import { ContactFormInterface, DataInterface } from "./types";
import { selectContactForm, selectForm, selectPageNo, selectPageSize, selectSortColumn, selectSortDir } from "./selector";
import { GetPolicy, GetUserContactList, UpdatePolicy, UpdateUserContactStatus } from "utils copy/request";
import { openSnackbar } from "store/reducers/snackbar";


export function* doUpdateRequest(action: {
  payload: {
    callback: any;
  };
}) {
  yield delay(500);
  try {

    const form: DataInterface = yield select(selectForm);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UpdatePolicy, form);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setLoading(false));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDataRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetPolicy,
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {

      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setDataInForm(response.data.data));

    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    const form: ContactFormInterface = yield select(selectContactForm);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetUserContactList, `?pageNo=${pageNo}&pageSize=${pageSize}&queryStatus=${form.queryStatus}${SortColumn.length > 0 ? "&sortColumn=" + SortColumn : ""
      }${SortDir.length > 0 ? "&sortDir=" + SortDir : ""}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalList(response.data.data[0].meta.total));
    yield put(actions.setList(response.data.data[0].results));

  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
} export function* doUpdateStatusRequest(action: {
  payload: {
    id: string;
    status: number;
    callback: any;
  };
}) {
  yield delay(500); 
  try {

    const data = {
      queryStatus: action.payload.status
    }
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UpdateUserContactStatus, action.payload.id, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* PolicyRepoSaga() {
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(
    actions.doGetData,
    doGetDataRequest
  );
  yield takeLatest(
    actions.doGetList,
    doGetListRequest
  );
  yield takeLatest(
    actions.doUpdateStatusData,
    doUpdateStatusRequest
  );
}
