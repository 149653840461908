import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actions } from './slice';
import { GetComisionAndAllComisionRequest, UpdateMarkupRequest } from 'utils copy/request';
import { selectConvenienceMarkupForm } from './selector';
import { ConvenienceInterface } from '../types';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'components/CatchBlockFunction';

export function* doGetComisionAndAllComisionRequest() {
  yield delay(500);

  try {
    // yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetComisionAndAllComisionRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }
    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* updateMarkupRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ConvenienceInterface = yield select(selectConvenienceMarkupForm);

  if (form.DomesticDiscountValue.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill domestic discount value',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.InternationalDiscountValue.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill international discount value',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(UpdateMarkupRequest, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    ;
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* authRepoSagaMarkup() {
  yield takeLatest(actions.doGetMarkupList, doGetComisionAndAllComisionRequest);
  yield takeLatest(actions.doUpdate, updateMarkupRequest);
}
