// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  CalendarOutlined,
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  IdcardOutlined,
  PictureOutlined,
  FileProtectOutlined,
  FilterOutlined,
  MoneyCollectOutlined,
  GlobalOutlined,
  TransactionOutlined,
  CustomerServiceOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  IdcardOutlined,
  PictureOutlined,
  FileProtectOutlined,
  FilterOutlined,
  MoneyCollectOutlined,
  GlobalOutlined,
  TransactionOutlined,
  CustomerServiceOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const custom: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'homepage',
      title: <FormattedMessage id="Homepage Banner" />,
      type: 'item',
      icon: icons.UserOutlined,
      url: '/homepage/banner'
    },
    {
      id: 'user',
      title: <FormattedMessage id="User" />,
      type: 'item',
      icon: icons.UserOutlined,
      url: '/user/user'
    },
    {
      id: 'bookings',
      title: <FormattedMessage id="Bookings" />,
      type: 'collapse',
      icon: icons.CalendarOutlined,
      children: [
        {
          id: 'bookingslist',
          title: <FormattedMessage id="Bookings List" />,
          type: 'item',
          url: '/bookings/bookingslist',
          breadcrumbs: false
        },
        {
          id: 'cancel-booking-query',
          title: <FormattedMessage id="Cancel Booking Query" />,
          type: 'item',
          url: '/bookings/cancel-booking-query'
        }
      ]
    },
    {
      id: 'refund',
      title: <FormattedMessage id="Refund" />,
      type: 'collapse',
      icon: icons.BellOutlined,
      children: [
        {
          id: 'product-list',
          title: <FormattedMessage id="Refund list" />,
          type: 'item',
          url: '/refund/refundlist',
          breadcrumbs: false
        },
        {
          id: 'add-new-product',
          title: <FormattedMessage id="Recent Refund" />,
          type: 'item',
          url: '/refund/recentrefund'
        }
      ]
    },

    {
      id: 'transaction',
      title: <FormattedMessage id="Transaction" />,
      type: 'item',
      icon: icons.TransactionOutlined,
      url: '/transaction/recenttransaction'
    },
    {
      id: 'faq',
      title: <FormattedMessage id="Faq" />,
      type: 'collapse',
      icon: icons.QuestionCircleOutlined,
      children: [
        {
          id: 'faq-list',
          title: <FormattedMessage id="List" />,
          type: 'item',
          url: '/faq/product-list',
          breadcrumbs: false
        }
        // {
        //   id: 'add-new-product',
        //   title: <FormattedMessage id="Add New Question" />,
        //   type: 'item',
        //   url: '/faq/add-new-product'
        // }
      ]
    },
    // {
    //   id: 'announcement',
    //   title: <FormattedMessage id="Announcement" />,
    //   type: 'collapse',
    //   icon: icons.AudioOutlined,
    //   children: [
    //     {
    //       id: 'announcement-list',
    //       title: <FormattedMessage id="List" />,
    //       type: 'item',
    //       url: '/announcement/announcementlist',
    //       breadcrumbs: false
    //     }
    //   ]
    // },
    {
      id: 'offers',
      title: <FormattedMessage id="Offers" />,
      type: 'collapse',
      icon: icons.TagOutlined,
      children: [
        {
          id: 'offer-list',
          title: <FormattedMessage id="List" />,
          type: 'item',
          url: '/offers/product-list',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'coupon',
      title: <FormattedMessage id="Coupon" />,
      type: 'collapse',
      icon: icons.DisconnectOutlined,
      children: [
        {
          id: 'coupon-list',
          title: <FormattedMessage id="List" />,
          type: 'item',
          url: '/coupon/product-list',
          breadcrumbs: false
        }
      ]
    },
    // {
    //   id: 'profile',
    //   title: <FormattedMessage id="profile" />,
    //   type: 'collapse',
    //   icon: icons.IdcardOutlined,
    //   children: [
    //     {
    //       id: 'account-profile',
    //       title: <FormattedMessage id="account-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/account/role'
    //     }
    //   ]
    // },

    {
      id: 'convenience',
      title: <FormattedMessage id="Convenience & Markup" />,
      type: 'item',
      icon: icons.MoneyCollectOutlined,
      url: '/convenience/ConvenientForm'
    },

    {
      id: 'markup',
      title: <FormattedMessage id="Markup" />,
      type: 'item',
      icon: icons.FilterOutlined,
      url: '/markup/markuplist'
    },
    {
      id: 'airline',
      title: <FormattedMessage id="Airline" />,
      type: 'item',
      icon: icons.GlobalOutlined,
      url: '/airline/list'
    },
    {
      id: 'ploicy',
      title: <FormattedMessage id="Policy" />,
      type: 'item',
      icon: icons.FileProtectOutlined,
      url: '/policy'
    },
    {
      id: 'usecontactus',
      title: <FormattedMessage id="User Contact Us" />,
      type: 'item',
      icon: icons.CustomerServiceOutlined,
      url: '/usercontactlist'
    }
  ]
};

export default custom;
