import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { flightDiscountTypeEnum, NewMarkupInterface, NewMarkupState } from '../types';

export const initialState: NewMarkupState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  NewMarkupList: [],
  NewMarkupFormData: {
    _id: '',
    flightDiscountPercentage: '-',
    flightDiscountType: flightDiscountTypeEnum.VALUE,
    flightDiscountValue: '-',
    flightType: ''
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  airlinesList: [],
  deleteMarkup: false,
  deleteLoading: false,
  submitLoading: false
};

export const useClinicSlice = createSlice({
  name: 'NewMarkupState',
  initialState,
  reducers: {
    updateNewMarkupFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      //@ts-ignore
      state.NewMarkupFormData[action.payload.key] = action.payload.value;
    },
    NewMarkupAdded: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetMarkupList: (state) => {},
    doGetAirlinesList: (state) => {},
    clearMarkupFrom: (state) => {
      state.NewMarkupFormData._id = '';
      state.NewMarkupFormData.flightType = '';
      state.NewMarkupFormData.flightDiscountValue = '';
      state.NewMarkupFormData.flightDiscountPercentage = '';
      state.NewMarkupFormData.flightDiscountType = flightDiscountTypeEnum.VALUE;
    },
    updateDataHelp: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setMarkupForEdit: (state, action: any) => {
      state.NewMarkupFormData = action.payload;
    },
    MarkupEdit: (state, action: PayloadAction<string>) => {},
    setMarkupList: (state, action: PayloadAction<Array<NewMarkupInterface>>) => {
      state.NewMarkupList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setairlinesList: (state, action: PayloadAction<any>) => {
      state.airlinesList = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    MarkupDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    setDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.deleteLoading = action.payload;
    },
    setSubmitLoading: (state, action: PayloadAction<boolean>) => {
      state.submitLoading = action.payload;
    },
    toggleDeleteMarkup: (state, action: PayloadAction<boolean>) => {
      state.deleteMarkup = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
