import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { GetRoleUserEditRequest, GetRoleUserlistRequest, SaveRoleUserRequest } from 'utils copy/request';
import { RoleUserInterface } from '../types';
import { SelectPageNo,  selectRoleUserForm, SelectSearch } from './selector';
import { openSnackbar } from 'store/reducers/snackbar';
export function* RoleUserAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: RoleUserInterface = yield select(selectRoleUserForm);

  if (form.email.length == 0) {
    openSnackbar({
      open: true,
      message: "please fill all fields",
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    })
    return;
  }
  if (form.fullName.length == 0) {
    openSnackbar({
      open: true,
      message: "please fill all fields",
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    })
    return;
  }
  if (form.password.length == 0) {
    openSnackbar({
      open: true,
      message: "please fill all fields",
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    })
    return;
  }
  if (form.phoneno.length == 0) {
    openSnackbar({
      open: true,
      message: "please fill all fields",
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    })
    return;
  }
  if (form.username.length == 0) {
    openSnackbar({
      open: true,
      message: "please fill all fields",
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    })
    return;
  }
  try {
    const response: AxiosResponse = yield call(SaveRoleUserRequest, form);
    if (response && !response.data) {
      // toast.error(response.data.message);
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          const element = dataerrer[index];
             openSnackbar({
          open: true,
          message: element.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
        }
      } else {
           openSnackbar({
          open: true,
          message: error.response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      }
    } else {
         openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
    }
  }
}
export function* getUserListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    // const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetRoleUserlistRequest,
      `?pageNo=${pageNo}&pageSize=${10000}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setDayList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          // const element = dataerrer[index];
          // toast.error(element.message);
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
       openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
    }
  }
}
export function* UserEditRequest(action: { payload: any }) {
  yield put(actions.setUserForEdit(action.payload));
}
export function* updateRoleuserRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: RoleUserInterface = yield select(
    selectRoleUserForm
  );

  // if (form.question.length === 0) {
  //   // toast.error("please fill Question");
  //   return;
  // }
  // if (form.answer.length === 0) {
  //   // toast.error("please fill Answer");
  //   return;
  // }
  try {
    const response: AxiosResponse = yield call(GetRoleUserEditRequest, form)
    if (response && !response.data) {
      // toast.error(response.data.message)
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback);

  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map(
          (item: any) => {
            return { name: item.param, message: item.msg };
          }
        );
        for (let index = 0; index < dataerrer.length; index++) {
          const element = dataerrer[index];
             openSnackbar({
          open: true,
          message: element.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
        }
      } else {
        // toast.error(error.response.data.message);
      }
    } else {
       openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
    }
  }

  // yield put(actions.updateSuccessHelpMe());
  // yield call(action?.payload?.callback);
}
export function* authRepoSagaRoleUser() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.NewRoleUserAdded, RoleUserAddRequest);
  yield takeLatest(actions.doGetRoleUserList, getUserListRequest);
  yield takeLatest(actions.userEdit, UserEditRequest);
  yield takeLatest(actions.updateDataRoleUser, updateRoleuserRequest);
}

// questionAddRequestMain
