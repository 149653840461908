'use client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { set } from 'lodash';
import { AirlineStatusEnum, DataInterface, ImageInterface } from './types';

export const useClinicSlice = createSlice({
  name: 'airlineState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setTotalList: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearFrom: (state) => {
      state.form._id = '';
      state.form.code = '';
      state.form.name = '';
      state.form.image = 'image';
      state.form.imageStatus = [];
      state.form.isActive = AirlineStatusEnum.ACTIVE;
    },
    doAdd: (state,action: PayloadAction<{callback: () => void;}>) => {},
    doGetList: (state) => {},
    doUpdate: (state,action: PayloadAction<{callback: () => void;}>) => {},
    doGetDataById: (state,action: PayloadAction<{id: string; callback: () => void;}>) => {},
    setDataInForm: (state, action: PayloadAction<DataInterface>) => {
      state.form._id = action.payload._id;
      state.form.name = action.payload.name;
      state.form.code = action.payload.code;
      state.form.isActive = action.payload.isActive;
      state.form.image = action.payload.image;
      if (action.payload.image) {
        state.form.imageStatus.push({
          image: action.payload.image,
          isDelete: false
        });
      } else {
        state.form.imageStatus.push({
          image: '',
          isDelete: false
        });
      }
    },
    setList: (state, action: PayloadAction<Array<DataInterface>>) => {
      state.list = action.payload;
    },
    setImageList: (state, action: PayloadAction<ImageInterface>) => {
      state.form.imageStatus.push(action.payload);
    },
    setImageStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.form.imageStatus[i].isDelete = true;
      }
    },
    doAddImage: (state, action: PayloadAction<{ file: any; callback: any }>) => {},
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
