import axios from 'axios';
let url = '';
let statucurl = '';
if (process.env.NODE_ENV === 'development') {
  url = 'https://api.trivzy.bigint.in/api/v1';
  statucurl = 'https://api.trivzy.bigint.in';
} else {
  url = 'https://api.trivzy.bigint.in/api/v1';
  statucurl = 'https://api.trivzy.bigint.in';
}
export const path = () => {
  return statucurl;
};
export default axios.create({
  baseURL: url
});
export const BASE_URL = () => {
  return url;
};
