export interface NewMarkupInterface {
  _id: string;
  flightType: string;
  flightDiscountType: flightDiscountTypeEnum;
  flightDiscountValue: any;
  flightDiscountPercentage: any;
}
export enum flightDiscountTypeEnum {
  VALUE = 1,
  PERCENTAGE = 0
}
export interface NewMarkupState {
  NewMarkupList: NewMarkupInterface[];
  NewMarkupFormData: NewMarkupInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  airlinesList: any;
  deleteMarkup: boolean;
  deleteLoading: boolean;
  submitLoading: boolean;
}

export type InitialMileStoneStata = NewMarkupState;
