import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';

import CatchBlockFunction from 'components/CatchBlockFunction';
import { DataInterface } from './types';
import { selectForm, selectPageNo, selectPageSize, selectSortColumn, selectSortDir } from './selector';
import { UpdateCancelBookingQueryStatus, GetCancelBookingQueryList } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';

export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    const form: DataInterface = yield select(selectForm);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetCancelBookingQueryList,
      `?pageNo=${pageNo}&pageSize=${pageSize}&queryStatus=${form.queryStatus}${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalList(response.data.data[0].meta.total));
    yield put(actions.setList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateStatusRequest(action: {
  payload: {
    id: string;
    status: number;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const data = {
      queryStatus: action.payload.status
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UpdateCancelBookingQueryStatus, action.payload.id, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* CancelBookingQueryRepoSaga() {
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doUpdateStatusData, doUpdateStatusRequest);
}
