import { PayloadAction } from '@reduxjs/toolkit';
import { LoginMessage } from 'Messages/LoginMessage';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { AuthState } from '../types';
import { set } from 'lodash';

export const initialState: AuthState = {
  loading: false,
  loginFrom: { username: '', password: '', errors: [], success: '' },
  userData: {
    id: '',
    name: '',
    profilePic: '',
    email: '',
    username: '',
    usertype: 0
  },
  token: localStorage.getItem('token') || '',
  isLogin: true,
  updatePasswordFormData: { id: '', newPassword: '', password: '' }
};

export const useClinicSlice = createSlice({
  name: 'AuthState',
  initialState,
  reducers: {
    setUpdateTocken: (state, action: PayloadAction<string>) => {
      console.log('login update', action.payload);
      state.token = action.payload;
      localStorage.setItem('auth_token', action.payload);
    },

    setisLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setProfileData: (
      state,
      action: PayloadAction<{ id: string; name: string; proflePic: string; email: string; username: string; usertype: number }>
    ) => {
      state.userData.id = action.payload.id;
      state.userData.name = action.payload.name;
      state.userData.profilePic = action.payload.proflePic;
      state.userData.email = action.payload.email;
      state.userData.username = action.payload.username;
      state.userData.usertype = action.payload.usertype;
    },
    getUserProfile: (state) => {},

    //Working
    doLogin: (state, action: PayloadAction<{ callback: () => void }>) => {},
    //Working
    doLogOut: (state) => {
      state.isLogin = true;
    },
    addLoginError: (state, action: PayloadAction<string>) => {
      state.loginFrom.errors.push(action.payload);
    },
    //Working
    loginSuccess: (state) => {
      state.loginFrom.success = LoginMessage.LOGIN_SUCCESS_MESSAGE;
      // AuthProps.isLoggedIn = truesta
      // state.AuthProps.isLoggedIn= false;
      // state.isLogin = true
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      //@ts-ignore
      state.loginFrom[action.payload.key] = action.payload.value;
      state.loginFrom.errors = [];
    },
    userUpdatePasswordFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `updatePasswordFormData.${action.payload.key}`, action.payload.value);
    },
    doUpdatePassword: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearPasswordForm: (state) => {
      state.updatePasswordFormData.id = '';
      state.updatePasswordFormData.password = '';
      state.updatePasswordFormData.newPassword = '';
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
