import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

import { AxiosResponse } from 'axios';
import { ICoupon } from '../types';
import { selectCouponForm, SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import {
  DeleteCouponRequest,
  GetCouponEditRequest,
  GetCouponlistRequest,
  GeUploadeRequest,
  SaveCouponRequest,
  UpdateCouponRequest
} from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'components/CatchBlockFunction';

export function* CouponAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ICoupon = yield select(selectCouponForm);

  if (form.title.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.description.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(SaveCouponRequest, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getCouponListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetCouponlistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }

    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setDayList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* FileUploadRequest(action: { payload: { data: any; callback: any } }) {
  yield delay(500);
  //
  try {
    const response: AxiosResponse = yield call(GeUploadeRequest, action.payload.data);
    if (response && !response.data) {
      // toast.error(response.data.message);
      return;
    }
    yield put(
      actions.updateNewCouponFormValue({
        key: 'image',
        value: response.data.data.name
      })
    );
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* deleteCoupon(action: { payload: { id: string; callback: any } }) {
  yield delay(500);

  yield put(actions.setDeleteLoading(true));
  try {
    const response: AxiosResponse = yield call(DeleteCouponRequest, action.payload.id);
    yield put(actions.setDeleteLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setDeleteLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetCouponEditRequest, action.payload);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setCouponForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* updateCouponRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ICoupon = yield select(selectCouponForm);

  if (form.title.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.description.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(UpdateCouponRequest, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* authRepoSagaCoupon() {
  yield takeLatest(actions.NewCouponAdded, CouponAddRequest);
  yield takeLatest(actions.doGetCouponList, getCouponListRequest);
  yield takeLatest(actions.setSearch, getCouponListRequest);
  yield takeLatest(actions.doAddImages, FileUploadRequest);
  yield takeLatest(actions.CouponDelete, deleteCoupon);
  yield takeLatest(actions.CouponEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, updateCouponRequest);
}
