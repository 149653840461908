import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import CatchBlockFunction from 'components/CatchBlockFunction';
import { DataInterface } from './types';
import { selectForm, selectPageNo, selectPageSize, selectSearch, selectSortColumn, selectSortDir } from './selector';
import { saveBlog, GetBlogList, GetBlogById, deleteBlog, UpdateBlog, uplodeFlagImage, flagSingedUrl } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';

export function* doAddRequest(action: {
  payload: {
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const form: DataInterface = yield select(selectForm);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(saveBlog, form);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setLoading(false));
    yield call(action.payload.callback());

  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    const search: string = yield select(selectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetBlogList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${search.length > 0 ? '&search=' + search : ''}${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalList(response.data.data.meta.total));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDataByIdRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  
  try {
    const response: AxiosResponse = yield call(GetBlogById, action.payload.id);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setDataInForm(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: {
  payload: {
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const form: DataInterface = yield select(selectForm);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UpdateBlog, form);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setLoading(false));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doDeleteRequest(action: {
  payload: {
    id: string;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(deleteBlog, action.payload.id);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* FileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(flagSingedUrl, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: { payload: { data: any; signedUrl: string; result: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(uplodeFlagImage, action.payload.signedUrl, action.payload.data, action.payload.result);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    } else {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* BlogRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetDataById, doGetDataByIdRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doDelete, doDeleteRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadeImage, UploadRequest);
}
