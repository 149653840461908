// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../NewTypes/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  console.log("state.TicketInfoState", state);

  if (state) {
    return state.TicketInfoState;
  } else {
    return initialState;
  }
};
export const SelectTicketInfoList = createSelector(
  [selectDomain],
  (state) => state.TicketInfoList
);
export const SelectTicketInfoForm = createSelector(
  [selectDomain],
  (state) => state.TicketInfoFormData
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
