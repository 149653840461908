
import {
  BlogCategoryEnum,
  BlogState,
} from ".";


export const initialState: BlogState = {
  loading: false,
  search: "",
  form: {
    _id: "",
    title: "",
    description: "",
    category: BlogCategoryEnum.NON_TRENDING,
    image: "abc",
    imageStatus: [],
  },
  list: [],
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  sortColumn: "",
  sortDir: "",
};
