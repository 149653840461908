export interface ICoupon {
  _id: string;
  title: string;
  description: string;
  status: StatusEnum;
  couponDiscountType: CouponDiscountTypeEnum;
  couponDiscount: any;
  couponDiscountPercentage: any;
  createdAt: string;
  updatedAt: string;
}
export enum CouponDiscountTypeEnum {
  VALUE = 1,
  PERCENTAGE = 2,
  NONE = 0
}
export enum StatusEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface CouponState {
  CouponList: ICoupon[];
  CouponFormData: ICoupon;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  sortColumn: string;
  sortDir: string;
  deleteCoupon: boolean;
  deleteLoading: boolean;
}

export type InitialCouponState = CouponState;
