import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "NewTypes";


import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  console.log("state.Milestone", state);

  if (state) {
    return state.AddRoletate;
  } else {
    return initialState;
  }
};
export const selectRoleUserForm:any = createSelector(
  [selectDomain],
  (state) => state.RoleUserFormData
);
export const selectRoleUserListData = createSelector(
  [selectDomain],
  (state) => state.RoleUserList
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);


