import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "../../../utils copy/@reduxjs/toolkit";
import { FaqInterface, FaqState } from "../types";
export const initialState: FaqState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  FaqList: [],
  FaqFormData: {
    _id: "",
    answer: "",
    faqid: "",
    question: ""
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  sortColumn: "",
  sortDir: ""
};

export const useClinicSlice = createSlice({
  name: "faqState",
  initialState,
  reducers: {
    updateNewFaqFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {

      //@ts-ignore
      state.FaqFormData[action.payload.key] = action.payload.value;
    },
    NewFaqAdded: (state, action: PayloadAction<{ callback: () => void }>) => { },
    clearNewFaqFrom: (state) => {
      state.FaqFormData.question = "";
      state.FaqFormData.answer = "";
      state.FaqFormData._id = "";
    },
    FAQDelete: (state, action: PayloadAction<any>) => {
      // state.FaqList.splice(action.payload , 1)
    },

    doGetDataById: (
      state,
      action: PayloadAction<{
        id: string,
        callback: () => void;
      }>
    ) => { },
    doGetFaqList: (state) => { },
    setLoading: (state, action: PayloadAction<boolean>) => {

      state.loading = action.payload;
    }, setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {

      state.totalRow = action.payload;
    },
    setDayList: (
      state, action: PayloadAction<Array<FaqInterface>>
    ) => {
      state.FaqList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {

      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {

      state.pageSize = action.payload;
    },
    setFaqForEdit: (
      state,
      action: PayloadAction<FaqInterface>
    ) => {
      state.FaqFormData = action.payload;
    },
    updateDataHelp: (state, action: PayloadAction<{ callback: () => void }>) => { },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }

});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
