import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../NewTypes";

const selectDomain = (state: RootState) => {
  if (state && state.policyState) {
    return state.policyState;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectContactForm = createSelector([selectDomain], (state) => state.contactForm);
export const selectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const selectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const selectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const selectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const selectSortDir = createSelector([selectDomain], (state) => state.sortDir);
