export interface UsersInterface {
  about: string;
  address: string;
  city: string;
  createdAt: string;
  dob: string;
  email: string;
  fullName: string;
  gender: GenderEnum;
  maritalStatus: UserMaritalStatusEnum;
  pinCode: string;
  profileCompleted: boolean;
  profilePic: string;
  state: string;
  status: 1;
  updatedAt: string;
  username: string;
  usertype: number;
  _id: string;
}
export interface PassengerInterface {
  _id: string;
  Title: string;
  honorifics: string;
  userId: string;
  fullName: string;
  FirstName: string;
  LastName: string;
  type: string;
  Gender: number;
  DateOfBirth: string;
  PassportNo: string;
  PassportExpiry: string;
  CountryCode: string;
  CellCountryCode: string;
  ContactNo: string;
  Email: string;
  AddressLine1: string;
  checked: boolean;
  PaxType: number;
  IsLeadPax: boolean;
  createdAt: string;
  updatedAt: string;
  AddressLine2: string;
  City: string;
  FFAirlineCode: number;
  FFNumber: string;
  GSTCompanyAddress: string;
  GSTCompanyContactNumber: string;
  GSTCompanyEmail: string;
  GSTCompanyName: string;
  GSTNumber: string;
  Nationality: string;
}
export interface UsersProfileInterface {
  _id: string;
  fullName: string;
  email: string;
  phoneNo: string;
  googleId: string;
  username: string;
  picupdated: number;
  profilePic: string;
  usertype: number;
  about: string;
  link: string;
  device_token: string;
  status: number;
  address: string;
  city: string;
  state: string;
  pinCode: string;
  profileCompleted: boolean;
  maritalStatus: number;
  gender: number;
  createdAt: string;
  updatedAt: string;
  dob: string;
  passengers: PassengerInterface[];
  passengerCount: number;
}

export interface UserBookingDetailsInterface {
  _id: string;
  id: string;
  tripDate: string;
  returnDate: string;
  userID: string;
  BookingId: string;
  PNR: string;
  stops: number;
  Fare: [
    {
      TicketId: number;
      TicketNumber: string;
      bookingStatus: number;
    }
  ];
  bookingDetailsRequest: {
    AirlineCode: string;
    AirlineName: string;
    FlightNumber: string;
    FareClass: string;
    OriginAirportName: string;
    OriginCityName: string;
    DepTime: string;
    DestinationAirportName: string;
    DestinationCityName: string;
    ArrTime: string;
  };
}

export enum GenderEnum {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3
}

export enum UserMaritalStatusEnum {
  MARRIED = 1,
  UNMARRIED = 2
}
export enum FlightBookingDetailFilterEnum {
  Past = 0,
  Upcoming = 1,
  Cancel = 2,
  Payment_failed = 3,
  Refund = 4
}

export interface UserState {
  UserList: UsersInterface[];
  userProfile: UsersProfileInterface;
  userBooking: UserBookingDetailsInterface[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  sortColumn: string;
  sortDir: string;
  status: FlightBookingDetailFilterEnum;
}

export type InitialMileStoneStata = UserState;
