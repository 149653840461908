import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../NewTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {


  if (state) {
    return state.MarkupState;
  } else {
    return initialState;
  }
};

export const selectConvenienceMarkupForm = createSelector([selectDomain], (state) => state.ConvenienceMarkupFormData);

export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
