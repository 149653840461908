import { PayloadAction } from "@reduxjs/toolkit";
// import { BookingsState } from "pages/bookings/types";
import { createSlice } from "../../../utils copy/@reduxjs/toolkit";
import {  TicketInfoInterface, TicketInfoState } from "../types";

export const initialState: TicketInfoState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
TicketInfoList:[],

TicketInfoFormData:{
id:"",
referenceNo:""
},
  editIndex: 0,
  isadd: false,
  dissableCheck: false

};

export const useClinicSlice = createSlice({
  name: "TicketInfoState",
  initialState,
  reducers: {
    updateNewFaqFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      console.log(
        "action.payload.key,action.payload.value",
        action.payload.key,
        action.payload.value
      );
      //@ts-ignore
      state.TicketInfoFormData[action.payload.key] = action.payload.value;
    },
    NewFaqAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log("Yo Reached at UserAdded");
      // state.FaqList.push(state.FaqFormData);
    },
    doGetBookingList: (state) => {},
    setBookingsList: (
      state,action: PayloadAction<Array<TicketInfoInterface>>
    ) => {
     state.TicketInfoList=action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      console.log("Loading");
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      console.log("pageNo");
      state.totalRow = action.payload;
    },
    setDayList: (
      state,action: PayloadAction<Array<any>>
    ) => {
     state.TicketInfoList=action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      console.log("pageNo");
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      console.log("pageSize");
      state.pageSize = action.payload;
    },    
  }
 
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
