import { call, delay,  put,  select ,  takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
// import {  SelectPageNo, SelectPageSize, SelectSearch } from './selector';
import { GetTicketStatusRequest } from 'utils copy/request';
import { TicketInfoInterface } from '../types';
import { SelectTicketInfoForm } from './selector';
import { openSnackbar } from 'store/reducers/snackbar';
export function* getFaqListRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: TicketInfoInterface = yield select(SelectTicketInfoForm);

  if (form.referenceNo.length == 0) {
    openSnackbar({
      open: true,
      message: "please fill all fields",
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    })
    return;
  }

  try {
    const response: AxiosResponse = yield call(GetTicketStatusRequest, form.referenceNo);
    if (response && !response.data) {
      // toast.error(response.data.message);
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    console.log(response.data.data , "ticket")
    yield put(actions.setDayList(response.data.data));

    yield call(action?.payload?.callback);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          const element = dataerrer[index];
             openSnackbar({
          open: true,
          message: element.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
        }
      } else {
           openSnackbar({
          open: true,
          message: error.response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      }
    } else {
         openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
    }
  }
}

export function* authRepoSagaTicketInfo() {
  // yield takeLatest(actions.doGetBookingList, getFaqListRequest);
  yield takeLatest(actions.NewFaqAdded, getFaqListRequest);

}

