import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../NewTypes/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  if (state && state.homepageBannerState) {
    return state.homepageBannerState;
  } else {
    return initialState;
  }
};


export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonloading
);
export const selectForm = createSelector(
  [selectDomain],
  (state) => state.form
);