export interface DataInterface {
  privacyPolicyDescription: string;
  tmcDescription: string;
  refundPolicyDescription: string;
}
export interface ContactFormInterface {
  _id: string;
  name: string;
  email: string;
  phoneNo: string;
  description: string;
  queryStatus: ContactUsQueryStatusEnum;
}

export enum ContactUsQueryStatusEnum {
  ALL = 0,
  RESOLVE = 1,
  NOT_RESOLVE = 2,
}
export interface PolicyState {
  form: DataInterface;
  list: ContactFormInterface[];
  contactForm: ContactFormInterface;
  loading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  sortColumn: string;
  sortDir: string;
}

export type InitialPolicyState = PolicyState;
