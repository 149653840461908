// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../NewTypes/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  

  if (state) {
    return state.DashboardState;
  } else {
    return initialState;
  }
};
export const SelectDashboardDataList = createSelector(
  [selectDomain],
  (state) => state.DashboardList
);
export const SelectDashboardGraphData = createSelector(
  [selectDomain],
  (state) => state.DashboardGraphDataList
);
export const SelectDashboardWalletData = createSelector(
  [selectDomain],
  (state) => state.DashboardWallet
);
export const SelectDashboardSearchData = createSelector(
  [selectDomain],
  (state) => state.DashboardSearchList
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);

export const SelectTransactionList = createSelector([selectDomain], (state) => state.TransactionList);