export interface DataInterface {
  _id: string;
  id: string;
  tripDate: string;
  userID: string;
  userName: string;
  email: string;
  BookingId: string;
  PNR: string;
  airlineName: string;
  status: FlightBookingStatusEnum;
}
export interface PassengersInterface {
  _id: string;
  Title: string;
  FirstName: string;
  LastName: string;
  DateOfBirth: string;
  PaxType: PassengerPaxTypeEnum;
  Gender: number;
  PassportNo: string;
  PassportExpiry: string;
  AddressLine1: string;
  City: string;
  CountryCode: string;
  CellCountryCode: string;
  ContactNo: string;
  Email: string;
  IsLeadPax: false;
  PublishedFare: number;
  convenienceFeeAmount: number;
  markup: number;
  TicketId: number;
  TicketNumber: string;
  bookingStatus: FlightBookingStatusEnum;
}
export interface BookingDetailsInterface {
  _id: string;
  trivzyBookingId: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  isReturn: boolean;
  status: FlightBookingStatusEnum;
  convenienceFeeAmount: number;
  markupTotal: number;
  tripDate: string;
  PNR: string;
  BookingId: string;
  netTotal: number;
  bookingDetailsRequest: {
    AirlineCode: string;
    AirlineName: string;
    FlightNumber: string;
    FareClass: string;
    OriginAirportName: string;
    OriginCityName: string;
    DepTime: string;
    DestinationAirportName: string;
    DestinationCityName: string;
    ArrTime: string;
  };
  passengers: PassengersInterface[];
}
export enum StatusFilterEnum {
  Past = 0,
  Upcoming = 1,
  Cancel = 2,
  Payment_failed = 3,
  Refund = 4
}

export enum PassengerPaxTypeEnum {
  Adult = 1,
  Child = 2,
  Infant = 3,
}

export enum FlightBookingStatusEnum {
  Booked = 1,
  Pending = 2,
  PartiallyBooked = 3,
  Cancelled = 4,
  Failed = 5,
  Processing =6
}

export interface BookingsState {
  status: StatusFilterEnum;
  list: DataInterface[];
  bookingDetails: BookingDetailsInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  dissableCheck: boolean;
  sortColumn: string;
  sortDir: string;
}

export type InitialMileStoneStata = BookingsState;
