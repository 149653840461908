// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { sliceKey as MenusliceKey, reducer as menuReducer } from 'store/reducers/menu';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
import { sliceKey as FeqsliceKey, reducer as FaqReducer } from 'pages/faq/redux/slice';
import { authRepoSaga } from 'sections/auth/redux/saga';
import { sliceKey as AuthsliceKey, reducer as AuthReducer } from 'sections/auth/redux/slice';
import { authRepoSagaFAQ } from 'pages/faq/redux/saga';
import { sliceKey as FAQsliceKey, reducer as FAQReducer } from 'pages/faq/redux/slice';

import { sliceKey as AnnsliceKey, reducer as AnnReducer } from 'pages/announcement/redux/slice';
import { authRepoSagaAnnouncement } from 'pages/announcement/redux/saga';
import { sliceKey as RoleUsersliceKey, reducer as RoleUserReducer } from 'sections/apps/profiles/account/redux/slice';
import { authRepoSagaRoleUser } from 'sections/apps/profiles/account/redux/saga';
import { authRepoSagaMarkup } from 'pages/markup/redux/saga';
import { sliceKey as MarkupsliceKey, reducer as MarkupReducer } from 'pages/markup/redux/slice';

import { authRepoSagaRefund } from 'pages/refund/redux/saga';
import { sliceKey as RefundsliceKey, reducer as RefundReducer } from 'pages/refund/redux/slice';

import { authRepoSagaNewmRakup } from 'pages/MarkupNew/redux/saga';
import { sliceKey as NewMarkupsliceKey, reducer as NewMarkupReducer } from 'pages/MarkupNew/redux/slice';

import { authRepoSagaBookings } from 'pages/bookings/redux/saga';
import { sliceKey as BookingsSliceKey, reducer as BookingsReducer } from 'pages/bookings/redux/slice';

import { authRepoSagaUser } from 'pages/user/redux/saga';
import { sliceKey as UserSliceKey, reducer as UserReducer } from 'pages/user/redux/slice';

// import { authRepoSagadas} from 'pages/user/redux/saga';
import { sliceKey as DashboardSliceKey, reducer as DashboardReducer } from 'pages/dashboard/redux/slice';
import { authRepoSagaDashboard } from 'pages/dashboard/redux/saga';

import { sliceKey as TicketInfoSliceKey, reducer as TicketInfoReducer } from 'pages/TicketStatus/redux/slice';
import { authRepoSagaTicketInfo } from 'pages/TicketStatus/redux/saga';
import { authRepoSagaOffer } from 'pages/offers/redux/saga';
import { sliceKey as OfferSliceKey, reducer as OfferReducer } from 'pages/offers/redux/slice';
import { authRepoSagaCoupon } from 'pages/coupon/redux/saga';
import { sliceKey as CouponSliceKey, reducer as CouponReducer } from 'pages/coupon/redux/slice';
import { PolicyRepoSaga } from 'pages/Policy/saga';
import { sliceKey as PolicySliceKey, reducer as PolicyReducer } from 'pages/Policy/slice';
import { BlogRepoSaga } from 'pages/blog-section/saga';
import { sliceKey as BlogSliceKey, reducer as BlogReducer } from 'pages/blog-section/slice';
import { AirlineRepoSaga } from 'pages/Airline/saga';
import { sliceKey as AirlineSliceKey, reducer as AirlineReducer } from 'pages/Airline/slice';
import { sliceKey as CancelBookingQuerySliceKey, reducer as CancelBookingQueryReducer } from 'pages/Cancel-booking-query/slice';
import { CancelBookingQueryRepoSaga } from 'pages/Cancel-booking-query/saga';
import { sliceKey as homepageBannerSliceKey, reducer as homepageBannerReducer } from 'pages/homepageBanner/redux/slice';
import { homepageBannerRepoSaga } from 'pages/homepageBanner/redux/saga';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  useInjectReducer({ key: MenusliceKey, reducer: menuReducer });
  useInjectReducer({ key: FeqsliceKey, reducer: FaqReducer });
  useInjectReducer({ key: AuthsliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthsliceKey, saga: authRepoSaga });
  //Faq
  useInjectReducer({ key: FAQsliceKey, reducer: FAQReducer });
  useInjectSaga({ key: FAQsliceKey, saga: authRepoSagaFAQ });
  //Announcement
  useInjectReducer({ key: AnnsliceKey, reducer: AnnReducer });
  useInjectSaga({ key: AnnsliceKey, saga: authRepoSagaAnnouncement });
  //RoleUser
  useInjectReducer({ key: RoleUsersliceKey, reducer: RoleUserReducer });
  useInjectSaga({ key: RoleUsersliceKey, saga: authRepoSagaRoleUser });
  //Convenience & Markup
  useInjectReducer({ key: MarkupsliceKey, reducer: MarkupReducer });
  useInjectSaga({ key: MarkupsliceKey, saga: authRepoSagaMarkup });
  //Refund
  useInjectReducer({ key: RefundsliceKey, reducer: RefundReducer });
  useInjectSaga({ key: RefundsliceKey, saga: authRepoSagaRefund });
  //Markup
  useInjectReducer({ key: NewMarkupsliceKey, reducer: NewMarkupReducer });
  useInjectSaga({ key: NewMarkupsliceKey, saga: authRepoSagaNewmRakup });
  //Bookings
  useInjectReducer({ key: BookingsSliceKey, reducer: BookingsReducer });
  useInjectSaga({ key: BookingsSliceKey, saga: authRepoSagaBookings });
  //user
  useInjectReducer({ key: UserSliceKey, reducer: UserReducer });
  useInjectSaga({ key: UserSliceKey, saga: authRepoSagaUser });

  //dashboard

  useInjectReducer({ key: DashboardSliceKey, reducer: DashboardReducer });
  useInjectSaga({ key: DashboardSliceKey, saga: authRepoSagaDashboard });

  useInjectReducer({ key: TicketInfoSliceKey, reducer: TicketInfoReducer });
  useInjectSaga({ key: TicketInfoSliceKey, saga: authRepoSagaTicketInfo });

  useInjectReducer({ key: OfferSliceKey, reducer: OfferReducer });
  useInjectSaga({ key: OfferSliceKey, saga: authRepoSagaOffer });

  useInjectReducer({ key: CouponSliceKey, reducer: CouponReducer });
  useInjectSaga({ key: CouponSliceKey, saga: authRepoSagaCoupon });
  //policy
  useInjectReducer({ key: PolicySliceKey, reducer: PolicyReducer });
  useInjectSaga({ key: PolicySliceKey, saga: PolicyRepoSaga });
  //Blog
  useInjectReducer({ key: BlogSliceKey, reducer: BlogReducer });
  useInjectSaga({ key: BlogSliceKey, saga: BlogRepoSaga });
  //Airline
  useInjectReducer({ key: AirlineSliceKey, reducer: AirlineReducer });
  useInjectSaga({ key: AirlineSliceKey, saga: AirlineRepoSaga });
  //Airline
  useInjectReducer({ key: CancelBookingQuerySliceKey, reducer: CancelBookingQueryReducer });
  useInjectSaga({ key: CancelBookingQuerySliceKey, saga: CancelBookingQueryRepoSaga });
  //homepageBanner
  useInjectReducer({ key: homepageBannerSliceKey, reducer: homepageBannerReducer });
  useInjectSaga({ key: homepageBannerSliceKey, saga: homepageBannerRepoSaga });
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
