
export interface DataInterface {
  _id: string;
  title: string;
  description: string;
  category: BlogCategoryEnum;
  image: string;
  imageStatus: ImageInterface[];
}

export enum BlogCategoryEnum {
  TRENDING = 1,
  NON_TRENDING = 2
}
export interface ImageInterface {
  isDelete: boolean;
  image: string;
}
export interface BlogState {
  form: DataInterface;
  list: DataInterface[];
  loading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  sortColumn: string;
  sortDir: string;
}

export type InitialBlogState = BlogState;
