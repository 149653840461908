import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { HomepageBannerState, HomepageBannerInterface, ImageInterface } from '../types';

export const initialState: HomepageBannerState = {
  loading: false,
  form: {
    images: [],
    imageStatus: []
  },
  buttonloading: false
};

export const useClinicSliceTodayActivity = createSlice({
  name: 'homepageBannerState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonloading = action.payload;
    },
    updateImageValue: (state, action: PayloadAction<{ value: any }>) => {
      state.form.images.push(action.payload.value);
    },
    doGetList: (state) => {},
    setData: (state, action: PayloadAction<HomepageBannerInterface>) => {
      state.form.images = action.payload.images;
      if (action.payload.images) {
        for (const ele of action.payload.images) {
          state.form.imageStatus.push({
            image: ele,
            isDelete: false
          });
        }
      } else {
        state.form.imageStatus.push({
          image: '',
          isDelete: false
        });
      }
    },
    doAddImage: (state, action: PayloadAction<{ file: any; callback: (filePath: string) => void }>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doDelete: (state, action: PayloadAction<{ index: number; callback: any }>) => {
      state.form.images.splice(action.payload.index, 1);
    },
    clearForm: (state) => {
      state.form.images = [];
      state.form.imageStatus = [];
    },
    setImageList: (state, action: PayloadAction<ImageInterface>) => {
      state.form.imageStatus.push(action.payload);
    },
    setImageStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.form.imageStatus[i].isDelete = true;
      }
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSliceTodayActivity;
