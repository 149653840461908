import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { FlightBookingDetailFilterEnum, UserBookingDetailsInterface, UsersInterface, UsersProfileInterface, UserState } from '../types';

export const initialState: UserState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  UserList: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  sortColumn: '',
  sortDir: '',
  userProfile: {
    _id: '',
    fullName: '',
    email: '',
    phoneNo: '',
    googleId: '',
    username: '',
    picupdated: 0,
    profilePic: '',
    usertype: 0,
    about: '',
    link: '',
    device_token: '',
    status: 0,
    address: '',
    city: '',
    state: '',
    pinCode: '',
    profileCompleted: false,
    maritalStatus: 0,
    gender: 0,
    createdAt: '',
    updatedAt: '',
    dob: '',
    passengers: [],
    passengerCount: 0
  },
  userBooking: [],
  status: FlightBookingDetailFilterEnum.Upcoming
};

export const useClinicSlice = createSlice({
  name: 'UserState',
  initialState,
  reducers: {
    doGetUserList: (state) => {},
    setUserList: (state, action: PayloadAction<Array<UsersInterface>>) => {
      state.UserList = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
     ;
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
     
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
     
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      
      state.pageSize = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    doGetUserProfileDetails: (state, action: PayloadAction<string>) => {},
    setUserProfileDetails: (state, action: PayloadAction<UsersProfileInterface>) => {
      state.userProfile = action.payload;
    },
    doGetUserBookingDetails: (state, action: PayloadAction<{id: string; callback: () => void}>) => {},
    setUserBookingDetails: (state, action: PayloadAction<Array<UserBookingDetailsInterface>>) => {
      state.userBooking = action.payload;
    },
    setStatus: (state, action: PayloadAction<FlightBookingDetailFilterEnum>) => {
      state.status = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
