import { AxiosResponse } from 'axios';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { getRefundlistRequest, getRecentRefundlistRequest, CancleRefundRequest, GenerateRefund } from 'utils copy/request';
import { RefundInterface } from '../types';
import { SelectPageNo, SelectPageSize, selectRefundFormData, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import { openSnackbar } from 'store/reducers/snackbar';
import { actions } from './slice';
import CatchBlockFunction from 'components/CatchBlockFunction';

export function* doGetRefundListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getRefundlistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setRefundList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetRecentRefundListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getRecentRefundlistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setRecentRefundList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* PaymentAPIRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: RefundInterface = yield select(selectRefundFormData);

  if (form.message.length == 0) {
    openSnackbar({
      open: true,
      message: 'please fill all fields',
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    });
    return;
  }
  try {
    const response: AxiosResponse = yield call(CancleRefundRequest, form);
    if (response && !response.data) {
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGenerateRefundRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);

  try {
    yield put(actions.setGenerateRefund(true));
    const response: AxiosResponse = yield call(GenerateRefund, action.payload.id);
    yield put(actions.setGenerateRefund(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setGenerateRefund(false));
    CatchBlockFunction(error);
  }
}
export function* authRepoSagaRefund() {
  yield takeLatest(actions.doGetRefundList, doGetRefundListRequest);
  yield takeLatest(actions.doGetRecentRefundList, doGetRecentRefundListRequest);
  yield takeLatest(actions.payment, PaymentAPIRequest);
  yield takeLatest(actions.doGenerateRefund, doGenerateRefundRequest);
}
