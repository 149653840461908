import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { ImageInterface, OfferInterface, OfferState } from '../types';
import moment from 'moment';

export const initialState: OfferState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  OfferList: [],
  OfferFormData: {
    category: '',
    description: '',
    _id: '',
    image: 'abc',
    title: '',
    valid: '',
    imageStatus: []
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  sortColumn: '',
  sortDir: '',
  deleteOffer: false,
  deleteLoading: false
};

export const useClinicSliceTodayActivity = createSlice({
  name: 'OfferState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setOfferList: (state, action: PayloadAction<Array<OfferInterface>>) => {
      state.OfferList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    toggleDeleteOffers: (state, action: PayloadAction<boolean>) => {
      state.deleteOffer = action.payload;
    },
    updateNewOfferFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      //@ts-ignore
      state.OfferFormData[action.payload.key] = action.payload.value;
    },
    NewOfferAdded: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearNewOfferFrom: (state) => {
      state.OfferFormData._id = '';
      state.OfferFormData.category = '';
      state.OfferFormData.description = '';
      state.OfferFormData.title = '';
      state.OfferFormData.valid = '';
      state.OfferFormData.image = 'abc';
      state.OfferFormData.imageStatus = [];
    },
    OfferDelete: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetOfferList: (state) => {},
    UploadeOffer: (state, action: PayloadAction<any>) => {},
    setOfferForEdit: (state, action: PayloadAction<OfferInterface>) => {
      state.OfferFormData._id = action.payload._id;
      state.OfferFormData.title = action.payload.title;
      state.OfferFormData.description = action.payload.description;
      state.OfferFormData.valid = moment(action.payload.valid).format('YYYY-MM-DD').toString();
      state.OfferFormData.category = action.payload.category;
      state.OfferFormData.image = action.payload.image;
      if (action.payload.image) {
        state.OfferFormData.imageStatus.push({
          image: action.payload.image,
          isDelete: false
        });
      } else {
        state.OfferFormData.imageStatus.push({
          image: '',
          isDelete: false
        });
      }
    },
    updateDataHelp: (state, action: PayloadAction<{ key: string; data: any; callback: any }>) => {
      console.log('addimages');
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    doAddImage: (state, action: PayloadAction<{ file: any; callback: any }>) => {},
    setImageList: (state, action: PayloadAction<ImageInterface>) => {
      state.OfferFormData.imageStatus.push(action.payload);
    },
    setImageStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.OfferFormData.imageStatus[i].isDelete = true;
      }
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSliceTodayActivity;
