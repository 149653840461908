// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../NewTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state) {
    return state.RefundState;
  } else {
    return initialState;
  }
};
export const selectRefundFormData = createSelector([selectDomain], (state) => state.RefundFormData);
export const selectRefundListData = createSelector([selectDomain], (state) => state.RefundList);
export const selectRecentRefundList = createSelector([selectDomain], (state) => state.recentRefundList);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectDisableCheck = createSelector([selectDomain], (state) => state.dissableCheck);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const selectGenerateRefund = createSelector([selectDomain], (state) => state.generateRefund);
