import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

import { AxiosResponse } from 'axios';
import { FaqInterface } from '../types';
import { selectFaqForm, SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import { DeleteFaqRequest, GeFaqEdit, GetFaqlistRequest, SaveFaqRequest, UpdateHelpRequest } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'components/CatchBlockFunction';

export function* FaqAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: FaqInterface = yield select(selectFaqForm);

  if (form.question.length == 0) {
    openSnackbar({
      open: true,
      message: 'please fill all fields',
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    });
    return;
  }
  if (form.answer.length == 0) {
    openSnackbar({
      open: true,
      message: 'please fill all fields',
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    });
    return;
  }
  try {
    const response: AxiosResponse = yield call(SaveFaqRequest, form);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getFaqListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetFaqlistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      });
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setDayList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* deleteFaq(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select FAQ to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(DeleteFaqRequest, action.payload);

    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield put(actions.doGetFaqList());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* updateHelpRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: FaqInterface = yield select(selectFaqForm);

  if (form.question.length === 0) {
    // toast.error("please fill Question");
    return;
  }
  if (form.answer.length === 0) {
    // toast.error("please fill Answer");
    return;
  }
  try {
    const response: AxiosResponse = yield call(UpdateHelpRequest, form);
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      });
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetDataByIdRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(GeFaqEdit, action.payload.id);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setFaqForEdit(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* authRepoSagaFAQ() {
  yield takeLatest(actions.NewFaqAdded, FaqAddRequest);
  yield takeLatest(actions.doGetFaqList, getFaqListRequest);
  yield takeLatest(actions.FAQDelete, deleteFaq);
  yield takeLatest(actions.doGetDataById, doGetDataByIdRequest);
  yield takeLatest(actions.updateDataHelp, updateHelpRequest);
}
