// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../NewTypes/RootState";

import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  

  if (state) {
    return state.UserState;
  } else {
    return initialState;
  }
};
export const SelecUserList = createSelector(
  [selectDomain],
  (state) => state.UserList
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const selectUserProfile = createSelector([selectDomain], (state) => state.userProfile);
export const selectUserBooking = createSelector([selectDomain], (state) => state.userBooking);
export const selectStatus = createSelector([selectDomain], (state) => state.status);