// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../NewTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {


  if (state) {
    return state.NewMarkupState;
  } else {
    return initialState;
  }
};
export const selectMarkuptForm = createSelector([selectDomain], (state) => state.NewMarkupFormData);
export const SelectMarkUpList = createSelector([selectDomain], (state) => state.NewMarkupList);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectDisableCheck = createSelector([selectDomain], (state) => state.dissableCheck);
export const SelectAirlinesList = createSelector([selectDomain], (state) => state.airlinesList);
export const selectDeleteLoading = createSelector([selectDomain], (state) => state.deleteLoading);
export const selectDeleteMarkup = createSelector([selectDomain], (state) => state.deleteMarkup);
export const selectSubmitLoading = createSelector([selectDomain], (state) => state.submitLoading);
