import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "NewTypes/RootState";


import { initialState } from "./slice";

const selectDomain = (state: RootState) => {
  console.log("state.AnnouncementState", state);

  if (state) {
    return state.AnnouncementState;
  } else {
    return initialState;
  }
};

export const selectForm = createSelector(
  [selectDomain],
  (state) => state.form
);
export const selectAnnouncemenListData = createSelector(
  [selectDomain],
  (state) => state.AnnouncementList
);

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectDisableCheck = createSelector(
  [selectDomain],
  (state) => state.dissableCheck
);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const selectDelete = createSelector([selectDomain], (state) => state.delete);
export const selectDeleteLoading = createSelector([selectDomain], (state) => state.deleteLoading);