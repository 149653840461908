'use client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { set } from 'lodash';
import { ContactFormInterface, ContactUsQueryStatusEnum, DataInterface } from './types';

export const useClinicSlice = createSlice({
  name: 'policyState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setTotalList: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },

    doUpdate: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => { },
    doGetData: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => { },
    setDataInForm: (state, action: PayloadAction<DataInterface>) => {
      state.form = action.payload;
    },
    doGetList: (state) => { },
    doUpdateStatusData: (
      state,
      action: PayloadAction<{
        id: string, status: number;
        callback: () => void;
      }>
    ) => { },
    setList: (state, action: PayloadAction<Array<ContactFormInterface>>) => {
      state.list = action.payload;

    }, setContactUsFilter: (state, action: PayloadAction<ContactUsQueryStatusEnum>) => {
      state.contactForm.queryStatus = action.payload;
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
