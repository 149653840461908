import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import CatchBlockFunction from 'components/CatchBlockFunction';
import { DataInterface } from './types';
import { selectForm, selectPageNo, selectPageSize, selectSearch, selectSortColumn, selectSortDir } from './selector';
import { saveAirline, GetAirlineList, GetAirlineById, UpdateAirline, uploadAirlineImage } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';

export function* doAddRequest(action: {
  payload: {
    callback: any;
  };
}) {
  yield delay(500);
  const form: DataInterface = yield select(selectForm);

  if (form.name.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.code.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill code',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(saveAirline, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    const search: string = yield select(selectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetAirlineList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${search.length > 0 ? '&search=' + search : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalList(response.data.data.meta.total));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDataByIdRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(GetAirlineById, action.payload.id);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setDataInForm(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: {
  payload: {
    callback: any;
  };
}) {
  yield delay(500);
  const form: DataInterface = yield select(selectForm);

  if (form.name.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.code.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill code',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(UpdateAirline, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUploadImageRequest(action: { payload: { file: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(uploadAirlineImage, action.payload.file);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield put(
      actions.updateFormValue({
        key: 'image',
        value: response.data.data
      })
    );
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* AirlineRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetDataById, doGetDataByIdRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doAddImage, doUploadImageRequest);
}
