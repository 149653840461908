
export interface DataInterface {
  _id: string;
  name: string;
  email: string;
  phoneNo: string;
  description: string;
  queryStatus: QueryStatusEnum;
}

export enum QueryStatusEnum {
  ALL = 0,
  RESOLVE = 1,
  NOT_RESOLVE = 2
}
export interface CancelBookingQueryState {
  form: DataInterface;
  list: DataInterface[];
  loading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  sortColumn: string;
  sortDir: string;
}

export type InitialCancelBookingQueryState = CancelBookingQueryState;
