import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { selectLoginForm, selectUpdatePassword } from './selector';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { LoginRequest, MeProfileRequest, UpdatePassword } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'components/CatchBlockFunction';
import { UpdatePasswordInterface } from '../types';

export function* meProfileRequest() {
  yield delay(500);

  if (localStorage.getItem('auth_token')) {
    try {
      const response: AxiosResponse = yield call(MeProfileRequest);
      if (response && !response.data) {
        yield put(
          openSnackbar({
            open: true,
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: true
          })
        );
        return;
      }
      yield put(
        actions.setProfileData({
          id: response.data.data.id,
          name: response.data.data.fullName,
          proflePic: response.data.data.profile_pic_url,
          email: response.data.data.email,
          username: response.data.data.username,
          usertype: response.data.data.usertype

          // fullName:response.data.data.fullName
        })
      );

      yield put(actions.setisLogin(true));
    } catch (error: any) {
      CatchBlockFunction(error);
    }
  } else {
    yield put(actions.setisLogin(false));
  }
}
export function* meLogOutRequest() {
  yield delay(500);
  localStorage.removeItem('auth_token');
  yield put(actions.setisLogin(false));
}
export function* loginRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const { username, password } = yield select(selectLoginForm);
  if (username.length === 0) {
    openSnackbar({
      open: true,
      message: 'Incorrect username or password',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: true
    });
    return;
  }
  if (password.length === 0) {
    openSnackbar({
      open: true,
      message: 'Please enter password',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: true
    });
    return;
  }
  let data = {
    email: username,
    password: password
  };
  try {
    const response: AxiosResponse = yield call(LoginRequest, data);
    if (response && !response.data.status) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield put(actions.setUpdateTocken(response.data.token));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    // yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdatePasswordRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: UpdatePasswordInterface = yield select(selectUpdatePassword);
  if (form.password.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please enter password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.newPassword.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please enter new password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  let data = {
    newPassword: form.newPassword,
    password: form.password
  };

  try {
    const response: AxiosResponse = yield call(UpdatePassword, data);

    if (response.data.statusCode) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* authRepoSaga() {
  yield takeLatest(actions.doLogin, loginRequest);
  yield takeLatest(actions.getUserProfile, meProfileRequest);
  yield takeLatest(actions.doLogOut, meLogOutRequest);
  yield takeLatest(actions.doUpdatePassword, doUpdatePasswordRequest);
  // yield takeLatest(actions.logout.type, logoutRequest);
  // yield takeLatest(actions.forgotPassword.type, forgotPassword);
  // yield takeLatest(actions.resetPassword.type, resetPassword);
}
