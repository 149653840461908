
import {
  ContactUsQueryStatusEnum,
  PolicyState,
} from ".";


export const initialState: PolicyState = {
  loading: false,
  search: "",
  form: {
    privacyPolicyDescription: "",
    tmcDescription: "",
    refundPolicyDescription: "",
  },
  contactForm: {
    _id: "",
    name: "",
    email: "",
    phoneNo: "",
    description: "",
    queryStatus: ContactUsQueryStatusEnum.ALL
  },
  list: [],
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  sortColumn: "",
  sortDir: "",
};
