import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import {
  DashboardListRequest,
  GetDashboardGraphDataRequest,
  GetDashboardSearchRequest,
  TransactionListRequest,
  WalletDataRequest
} from 'utils copy/request';
import CatchBlockFunction from 'components/CatchBlockFunction';
import { openSnackbar } from 'store/reducers/snackbar';
export function* getDashboardListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      DashboardListRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      });
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setDashboardsList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getWalletInfoRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(WalletDataRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      });
      return;
    }
    yield put(actions.setWalletData(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getDashboardGraphDataRequest(data: any) {
  console.log(data.payload, 'getDashboardGraphDataRequest');
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetDashboardGraphDataRequest, data.payload);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      });
      return;
    }

    yield put(actions.setGraphData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getSearchDataRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetDashboardSearchRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      });
      return;
    }
    yield put(actions.setSearchData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetTransactionListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      TransactionListRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      });
      return;
    }

    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setTransactionListt(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* authRepoSagaDashboard() {
  yield takeLatest(actions.doGetDashboardList, getDashboardListRequest);
  yield takeLatest(actions.setSearch, getDashboardListRequest);
  yield takeLatest(actions.doGetDashboardGraphData, getDashboardGraphDataRequest);
  yield takeLatest(actions.doGetAgencyWallet, getWalletInfoRequest);
  yield takeLatest(actions.doGetSearchData, getSearchDataRequest);
  yield takeLatest(actions.doGetTransactionList, doGetTransactionListRequest);
}
