export interface DataInterface {
  _id: string;
  name: string;
  code: string;
  image: string;
  imageStatus: ImageInterface[];
  isActive: AirlineStatusEnum;
}
export enum AirlineStatusEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface ImageInterface {
  isDelete: boolean;
  image: string;
}
export interface AirlineState {
  form: DataInterface;
  list: DataInterface[];
  loading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  sortColumn: string;
  sortDir: string;
}

export type InitialAirlineState = AirlineState;
